import { ax } from "./index";

export default class AdminService {
  // Admin api services
  adminlogin(payLoad) {
    return ax.post("adminlogin", payLoad);
  }
  adminotpverification(payLoad) {
    return ax.post("adminotpverification", payLoad);
  }

  getorderparticipantlist(payLoad) {
    return ax.post("getorderparticipantlist", payLoad);
  }

  resendmail(payLoad) {
    return ax.post("resendmail", payLoad);
  }

  paymentstatusupdate(payLoad) {
    return ax.post("refundmail", payLoad);
  }

  // Adminui api services starts here

  getbibdetails(payLoad) {
    return ax.get("getbibdata", payLoad);
  }
  updatedeliverystatus(payLoad) {
    return ax.post("updatebibdata", payLoad);
  }
  addadminparticipant(payLoad) {
    return ax.post("adminaddparticipant", payLoad);
  }

  getorderCompanylist() {
    return ax.get("company");
  }
  
  addadminCompany(payload) {
    return ax.post( 'company',payload)
  }

  
  getAdminUserlist() {
    return ax.get("adminuser");
  }

  addadminPOC(payload) {
    return ax.post( 'adminuser',payload)
  }

  deleteadminCompany(id) {
    return ax.delete( `company/${id}`)
  }

  editAdminCompany(payload, id) {
    return ax.post( `company/${id}`,payload)
  }
  
  companylogin(payLoad) {
    return ax.post("companylogin", payLoad);
  }

  companyotpverification(payLoad) {
    return ax.post("companyotpverification", payLoad);
  }

  listcompanypartisipant() {
    return ax.get("companyparticipant");
  }

  deleteParticipantById(id) {
    return ax.delete( `company/${id}`)
  }

  approveParticipant() {
    return ax.post('companyparticipantapprove')
  }

  viewParticipant(id) {
    return ax.get(`companyparticipant/${id}`);
  }

  confirmParticipant(id){
    return ax.post(`confirmcompanyparticipant/${id}`)
  }

  blukUploadParticipants(payLoad){
    return ax.post("uploadparticipant", payLoad);
  }

  downloadBulkUploadExcel() {
    return ax.get(`admin/download-plantation-template`);
  }
}