import React, { useState, useEffect } from "react";
import Breadcrumbsbanner from "../../../components/sharedComponents/breadcumbbanner/breadcumbsbanner";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { navigate } from "gatsby";
import AdminService from "../../../components/services/admin.service";
import { Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import backn from "../../../assets/images/backIconSummary.svg";
import "react-toastify/dist/ReactToastify.css";
import { lStorage } from "../../../components/utils/storage";

export default function BasicForCompanyRegister() {
  const [isEdit, setIsEdit] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState("");

  const adminServices = new AdminService();

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    // setFocus,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const newData = {
      ...data,
     // poc_contact_number: data?.poc_contact_number.replace(/[- ]/g, "")?.replace("+91", "")
    }

    setButtonDisable(true);

if(!isEdit){
  await adminServices
  .addadminCompany(newData)
  .then((response) => {
    if (
      response.data.isError === false ||
      response.data.statusCode === "200"
    ) {
      if (response.data.isError === false) {
        toast.success("Company added successfully");
        reset();
        setButtonDisable(false);
        navigate("/admin/Company-registration");

      } else {
        setButtonDisable(false);
      }
    } else {
      if (response.data.isError === true) {
        setButtonDisable(false);
      }
    }
  })
  .catch(function (error) {
    setButtonDisable(false);
    // logOut();
  });
}else if(isEdit){
  await adminServices
  .editAdminCompany(newData, selectedId)
  .then((response) => {
    if (
      response.data.isError === false ||
      response.data.statusCode === "200"
    ) {
      if (response.data.isError === false) {
        toast.success("Company Updated successfully");
        reset();
        lStorage.remove("selectedCom");
        setButtonDisable(false);
        setIsEdit(false);
        navigate("/admin/Company-registration");

      } else {
        setButtonDisable(false);
        setIsEdit(false);
      }
    } else {
      if (response.data.isError === true) {
        setButtonDisable(false);
        setIsEdit(false);
      }
    }
  })
  .catch(function (error) {
    setButtonDisable(false);
    // logOut();
  });
}

  };

  useEffect(() => {
    const editDataObject= lStorage.get("selectedCom");
    if(editDataObject){
      setTimeout(() => {
        editData(editDataObject);
        setIsEdit(true);
      }, 650);
    }
  }, []);

  const editData = async (data) => {
    setSelectedId(data?.company_id)

    const fields = [
      "company_name",
      "poc_name",
      "poc_email_id",
      "poc_contact_number",
      "status",
      "registration_limit",
      "expiry_date",
    ];
    fields.forEach(
      (field) => setValue(field, data?.[field]),
    );
  };

  const backToList = () => {
    navigate("/admin/Company-registration/");
  };

  return (
    <>
      <Breadcrumbsbanner />
      <div
        className="Basic-form-wrapper inner-pages p-4"
        style={{ marginBottom: "120px" }}
      >
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="row">
              <div className="p-2">
                <Button
                  className="btn btn-primary"
                  onClick={() => backToList()}
                >
                  <img className="img-fluid" src={backn} alt="back" />
                  Back to List
                </Button>
              </div>
              <div>
                <h3> Add Company </h3>
              </div>
              <div className="col-md-9">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="company_name">
                          Company Name <span className="requiredColor">*</span>
                        </label>
                        <input
                          {...register("company_name", {
                            required: true,
                            pattern: /^\S[a-zA-Z .]*$/,
                            maxLength: 120,
                          })}
                          autoComplete="new-password"
                          className="form-control"
                        />
                        <span className="error_validation ml-3">
                          {errors.company_name?.type === "required" &&
                            "This field is required."}
                          {errors.company_name &&
                            errors.company_name.type === "maxLength" && (
                              <span>Max length exceeded</span>
                            )}
                          {errors.company_name &&
                            errors.company_name.type === "pattern" && (
                              <span>Please enter valid name</span>
                            )}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="poc_name">
                          POC Name <span className="requiredColor">*</span>
                        </label>
                        <input
                          {...register("poc_name", {
                            required: true,
                            pattern: /^\S[a-zA-Z .]*$/,
                            maxLength: 120,
                          })}
                          autoComplete="new-password"
                          className="form-control"
                        />
                        <span className="error_validation ml-3">
                          {errors.poc_name?.type === "required" &&
                            "This field is required."}
                          {errors.poc_name &&
                            errors.poc_name.type === "maxLength" && (
                              <span>Max length exceeded</span>
                            )}
                          {errors.poc_name &&
                            errors.poc_name.type === "pattern" && (
                              <span>Please enter valid name</span>
                            )}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="poc_email_id" className="form-label">
                          POC Email Address
                          <span className="requiredColor">*</span>
                        </label>
                        <input
                          type="poc_email_id"
                          className="form-control"
                          name="poc_email_id"
                          id="poc_email_id"
                          autoComplete="new-password"
                          {...register("poc_email_id", {
                            required: true,
                            pattern: {
                              value:
                                /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!test|testing)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/,
                              message: "Please enter a valid email",
                            },
                          })}
                          onChange={() => {
                            clearErrors("poc_email_id");
                          }}
                        />
                        <span className="error_validation ml-3">
                          {errors.poc_email_id?.type === "required" &&
                            "This field is required."}
                          {errors.poc_email_id?.message}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="poc_contact_number"
                          className="form-label"
                        >
                          POC Contact No<span className="requiredColor">*</span>
                        </label>

                        <Controller
                          className=""
                          control={control}
                          name="poc_contact_number"
                          rules={{
                            required: true,
                          }}
                          render={(props) => {
                            return (
                              <PhoneInput
                                country={"in"}
                                value={props.field.value}
                                name={props.field.name}
                                onChange={(
                                  value,
                                  country,
                                  e,
                                  formattedValue
                                ) => {
                                  // setCountryCode(country?.dialCode);
                                  props.field.onChange(formattedValue);
                                  // setSameNumber(false);
                                  clearErrors("poc_contact_number");
                                  // setPhoneNumber(formattedValue?.replace(/[- ]/g, "")?.replace("+91", ""));
                                }}
                                inputRef={props.field.ref}
                                enableSearch={true}
                                className="phoneInput"
                              />
                            );
                          }}
                        />

                        <span className="error_validation ml-3">
                          {errors.poc_contact_number?.type === "required" &&
                            "This field is required."}
                          {errors.poc_contact_number?.message}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="expiry_date" className="form-label">
                          Expiry Date <span className="requiredColor">*</span>
                        </label>
                        <div>
                          <input
                            type="date"
                            {...register("expiry_date", {
                              required: true,
                            })}
                            // max={datePickerId}
                            // min={minDateValue}
                            autoComplete="new-password"
                            className="form-control"
                            onChange={() => {
                              // setRaceError(false);
                              clearErrors("expiry_date");
                            }}
                          />
                        </div>
                        <span className="error_validation ml-3">
                          {errors.expiry_date?.type === "required" &&
                            "This field is required."}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="registration_limit"
                        >
                          Registration Limit
                          <span className="requiredColor">*</span>
                        </label>
                        <input
                          {...register("registration_limit", {
                            required: true,
                            pattern: /^[0-9]*$/,
                            maxLength: 120,
                          })}
                          autoComplete="new-password"
                          className="form-control"
                        />
                        <span className="error_validation ml-3">
                          {errors.registration_limit?.type === "required" &&
                            "This field is required."}
                          {errors.registration_limit &&
                            errors.registration_limit.type === "maxLength" && (
                              <span>Max length exceeded</span>
                            )}
                          {errors.registration_limit &&
                            errors.registration_limit.type === "pattern" && (
                              <span>Please enter numeric</span>
                            )}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="status" className="form-label">
                          Status
                          <span className="requiredColor">*</span>
                        </label>
                        <select
                          name="status"
                          className="form-control"
                          {...register("status", { required: true })}
                          onChange={(e) => {
                            // setAccommidationStatus(e.target.value);
                            // setValue(
                            //   "status",
                            //   e.target.value,
                            //   true
                            // );
                            clearErrors("status");
                          }}
                        >
                          <option value="">Select</option>
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                        </select>
                        <span className="error_validation ml-3">
                          {errors.status?.type === "required" &&
                            "This field is required."}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ float: "right" }} className="form-label">
                      &nbsp;
                      <button
                        type="button"
                        className=" btn btn-secondary btn-block"
                        value="Clear"
                        onClick={() => {
                          reset();
                          // setAccommidationStatus("");
                          // setIsEdit(false);
                          // lStorage.remove("editSecretId");
                        }}
                      >
                        Clear
                      </button>
                      &nbsp;&nbsp;
                      <input
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={buttonDisable}
                        value={isEdit ? "Update" : "Submit"}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={5000} />
    </>
  );
}
